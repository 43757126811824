import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="relative overflow-hidden pt-20 pb-20">
            <span className="footer-circle"></span>
            <div className="relative z-1">
                <p className="font-neutra text-white text-center text-[23px] lg:text-[40px] leading-none max-w-[260px] lg:max-w-[450px] -rotate-[4deg] block mx-auto">{t("footer_title")}</p>
                <a className="mt-[26px] rounded-3xl font-avenirbold w-fit text-strawberry-100 bg-white hover:bg-strawberry-100 hover:text-white text-[20px] leading-none py-[10px] px-[20px] block mx-auto" href={t("footer_cta_link")} rel="noreferrer" target="_blank">{t("footer_cta")}</a>
            </div>
        </div>
    );
};

export default Footer;