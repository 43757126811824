import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import Video from "../img/video.svg";
import Play from "../img/play.svg";
import Pause from "../img/pause.svg";
import Arrow from "../img/arrow.svg";
import Video1 from "../videos/video_1.mp4";
import Video2 from "../videos/video_2.mp4";
import Video1Thumb from "../videos/kommid.png";
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { FreeMode, Navigation, Thumbs, HashNavigation, Pagination } from 'swiper/modules';


const Products = () => {
    const { t } = useTranslation();
    const products = t("products", { returnObjects: true }) || [];

    const [selectedSteps, setSelectedSteps] = useState(Array(products.length).fill(1));
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);

    const handleChange = (index, event) => {
        /* const selectedValue = parseFloat(event.target.value);
         const newSelectedSteps = [...selectedSteps];
         newSelectedSteps[index] = selectedValue;
         setSelectedSteps(newSelectedSteps);*/
    };

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const videoRefs = useRef([React.createRef(), React.createRef()]);

    const handlePlayPause = (videoIndex) => {
        const video = videoRefs.current[videoIndex].current;

        if (videoIndex === 0) {
            setIsPlaying1(!isPlaying1);
            setIsPlaying2(false);
        } else {
            setIsPlaying1(false);
            setIsPlaying2(!isPlaying2);
        }

        if (isPlaying1 && videoIndex !== 0) {
            videoRefs.current[0].current.pause();
        } else if (isPlaying2 && videoIndex !== 1) {
            videoRefs.current[1].current.pause();
        }

        if (isPlaying1 || isPlaying2) {
            video.pause();
        } else {
            video.play();
        }
    };

    const handleSlideChangeTransitionEnd = () => {
        // Pause the previous videos when the slide change transition is complete
        if (isPlaying1) {
            videoRefs.current[1].current.pause();
            setIsPlaying2(false);
        } else if (isPlaying2) {
            videoRefs.current[0].current.pause();
            setIsPlaying1(false);
        }
    };

    const [swiper, setSwiper] = useState(null);

    const handleSwiper = (swiperInstance) => {
        setSwiper(swiperInstance);
    };

    const handleSlideChange = () => {
        // Pause the currently playing video when the slide changes
        if (isPlaying1) {
            videoRefs.current[0].current.pause();
            setIsPlaying1(false);
        } else if (isPlaying2) {
            videoRefs.current[1].current.pause();
            setIsPlaying2(false);
        }
    };

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    };
    const activityTypes = ["walk", "run", "ski", "swim"];
    const [selectedActivity, setSelectedActivity] = useState(activityTypes[0]); // Default to "walk"


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className="container lg:my-[91px] mx-auto max-w-[1156px] relative">
            <div className="hidden lg:flex justify-between lg:gap-2 px-4 lg:pb-4">
                <div className="custom-prev my-auto cursor-pointer"></div>

                <Swiper
                    onSwiper={setThumbsSwiper}
                    freeMode={true}
                    watchSlidesProgress={true}
                    navigation={{
                        nextEl: '.custom-next',
                        prevEl: '.custom-prev',
                    }}
                    modules={[Navigation, Thumbs]}
                    breakpoints={{
                        320: { slidesPerView: 1 },
                        480: { slidesPerView: 1 },
                        768: { slidesPerView: 3 },
                        1024: { slidesPerView: 5 },
                    }}

                >
                    {products.map((product, index) => (
                        <SwiperSlide key={index} className="cursor-pointer">
                            <img src={`https://magic.mixd.ee/mesikapp-2/products/${product.slug}.png`} alt={t(product.title)}/>
                            <p className="thumb-title text-center font-avenirbold text-[18px] lg:text-[16px] uppercase font-bold text-chocolate lg:px-2">{t(product.title)}</p>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="custom-next my-auto cursor-pointer"></div>
            </div>

                <div className="custom-prev cursor-pointer lg:hidden absolute top-[150px] left-2 z-10"></div>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                navigation={{
                    nextEl: '.custom-next',
                    prevEl: '.custom-prev',
                }}
                modules={[FreeMode, Navigation, Thumbs, HashNavigation]}
                className="mySwiper"
                hashNavigation={{
                    watchState: true,
                }}
                onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
                onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
            >
                {products.map((product, index) => {

                    const handleActivityButtonClick = (activityType) => {
                        setSelectedActivity(activityType);
                    };

                    const getCategoryDistance = () => {
                        const distance = parseFloat(product[selectedActivity][0].distance);
                        return distance * selectedSteps[index];
                    };

                    const getCategoryKcal = (distance) => {

                        return  parseFloat(product.kcal);
                    };

                    const getCategoryUnit = (distance) => {
                        return  parseFloat(product.unit);
                    };



                    const selectedDistance = getCategoryDistance();
                    const kcal = getCategoryKcal(selectedDistance);

                    return (
                        <SwiperSlide data-hash={`${t(product.slug)}`} key={index}>
                            <div className="lg:grid lg:grid-cols-2">
                                <div className="my-auto block">
                                    <img className="mx-auto active-product px-10" src={`https://magic.mixd.ee/mesikapp-2/products/${product.slug}.png`} alt={t(product.title)}/>
                                    <p className="thumb-title text-center font-avenirbold text-[18px] lg:text-[16px] uppercase font-bold text-chocolate lg:hidden pb-2">{t(product.title)}</p>
                                </div>

                                <div className="inputGroup lg:my-auto relative py-[20px] lg:pt-[80px] lg:pb-[50px] px-[63px]">
                                    <span className="inputcircle"></span>
                                    <div className="grid grid-cols-4 gap-4 relative z-1 categories mb-[19px] lg:mb-[27px]">
                                        {activityTypes.map((type) => (
                                            <button
                                                key={type}
                                                className={selectedActivity === type ? "active" : ""}
                                                onClick={() => handleActivityButtonClick(type)}
                                            >
                                                <img className="w-full lg:max-w-[82px] mx-auto h-auto relative z-10 rounded-full" src={`https://magic.mixd.ee/mesikapp-2/products/${type}.svg`} alt=""/>

                                            </button>
                                        ))}
                                    </div>

                                    {/* Range slider for the selected activity */}
                                    <div className="relative z-1 pt-[55px]">

                                        <div className="handle-bullet" style={{ left: `calc(${(selectedDistance / 3) * 100}% - 70px)` }}>
                                            {selectedDistance} {t("km")}
                                        </div>

                                        <div className="road mb-[27px]" style={{ width: "100%" }}></div>

                                        <p className="text-center lg:text-right text-chocolate font-avenir text-[20px]">
                                            1 {t(product.unit)}{` = ${getCategoryKcal().toFixed(2)} ${t("kcal")}`}
                                        </p>

                                        <div className="lg:w-[250px] lg:-rotate-[5.92deg] lg:flex flex-col justify-center items-center mx-auto rounded-full mt-4 lg:-mt-[30px] pb-[50px]">
                                            <img src={Arrow} className={"hidden lg:block h-auto mb-6 mr-[80px]"} alt="" />
                                            <p className="font-avenir text-chocolate text-center text-[21px] leading-none" dangerouslySetInnerHTML={{ __html: t("product_disclaimer") }}></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="custom-next cursor-pointer lg:hidden absolute top-[150px] right-2 z-10"></div>


            <div className="mt-[82px] lg:mt-[94px]">
                <p className="font-neutra text-strawberry-100 text-center text-[39px] lg:text-[40px] leading-none max-w-[260px] lg:max-w-[450px] -rotate-[2.61deg] block mx-auto mb-2 lg:mb-[17px]">{t("videos_title")}</p>
                <p className="font-neutra text-chocolate text-center text-[23px] lg:text-[26px] leading-none max-w-[360px] lg:max-w-[450px] -rotate-[2.61deg] block mx-auto">{t("videos_subtitle")}</p>
            </div>

            <Swiper
                onSwiper={handleSwiper}
                onSlideChange={handleSlideChange}
                pagination={pagination}
                modules={[Pagination]}
            >
                <SwiperSlide className="mb-[70px]">
                    <div className="bg-sand lg:w-[676px] lg:h-[470px] -rotate-[2.61deg] mx-6 lg:mx-auto rounded-[90px] flex overflow-hidden mt-[26px] relative">
                        <img className="w-full h-auto pointer-events-none relative z-10" src={Video} alt="Video"/>

                        <video
                            ref={videoRefs.current[0]}
                            className="absolute top-0 right-0 w-full h-full object-cover z-0"
                            src={Video1}
                            controls={false}
                            loop
                            poster={Video1Thumb}
                        ></video>

                        <div className="absolute inset-0 flex items-center justify-center">
                            <button onClick={() => handlePlayPause(0)} className="text-white text-4xl">
                                {isPlaying1 ? <img src={Pause} className={"w-[45px] h-auto"} alt="Paus" /> : <img src={Play} className={"w-[66px] h-auto"} alt="" />}
                            </button>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

        </div>
    );
};

export default Products;