import React from "react";
import { useTranslation } from "react-i18next";
import Rainforest from "../img/rainforest.png"

const Sustainability = () => {
    const { t } = useTranslation();

    return (
        <div className="container my-[48px] lg:my-[91px] mx-auto max-w-[1156px] relative">
            <div className="sustainability-circle flex flex-col">
                <img className="lg:order-2 lg:absolute max-w-[264px] lg:max-w-[455px] lg:right-0" src={Rainforest} alt=""/>
                <div className="lg:order-1 max-w-[429px] mt-[20px] lg:mt-0">
                    <p className="text-center lg:text-left text-white mb-[29px] font-avenirbold text-[23px] leading-none">{t("sustainability_content")}</p>
                    <a className="mt-[26px] rounded-3xl font-avenirbold w-fit text-strawberry-100 bg-white hover:bg-strawberry-100 hover:text-white text-[20px] leading-none py-[10px] px-[20px] block mx-auto lg:mx-0" href={t("sustainability_cta_link")} rel="noreferrer" target="_blank">{t("sustainability_cta")}</a>
                </div>

            </div>
        </div>
    );
};

export default Sustainability;