import React from "react";
import Mesikapp from "../img/mesikapp_pose.png"
import { useTranslation } from "react-i18next";
import Modal from '@mui/material/Modal';
import CloseIcon from '../img/close.svg'



const Hero = () => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div className="container mx-auto max-w-[1156px] relative pt-[52px] px-4">

            <div className="hero-container">
                <div className="grid md:grid-cols-2">
                    <div className="relative">
                        <span className="hero-circle"></span>
                       <div className="relative text-white pr-[115px] md:pr-0">
                           <h1 className="font-neutra uppercase max-w-[300px] md:max-w-full text-[39px] lg:text-[67px] leading-none ">{t("hero_title")}</h1>

                           <div className="flex flex-col lg:flex-row my-[17px] lg:my-[25px] gap-[17px] lg:gap-[29px] lg:items-center">
                               <p className="lg:order-2 font-avenirbold leading-[19px] text-[20px] lg:leading-[22px] lg:text-[23px] max-w-[450px]">{t("hero_subtitle")}</p>
                               <nobr><a onClick={handleOpen} className="play-btn lg:order-1 rounded-3xl font-avenirbold w-fit text-white text-[20px] leading-none py-[10px] px-[20px] block cursor-pointer">{t("hero_cta")}</a></nobr>

                               <Modal
                                   open={open}
                                   onClose={handleClose}
                                   aria-labelledby="modal-modal-title"
                                   aria-describedby="modal-modal-description"
                               >
                                   <div className="modal-item">

                                         <div className="absolute top-0 right-0 p-4 font-neutra" onClick={handleClose}><img src={CloseIcon} /></div>
                                         <iframe
                                           title="EndlessRunner"
                                           height="700px"
                                           className="w-[400px] lg:w-[900px]"
                                           src={t("game_url")}
                                           frameBorder="0"
                                       />

                                   </div>
                               </Modal>

                           </div>

                           <p className="font-avenir text-[20px] mb-[27px] lg:mb-[70px] max-w-[450px] lg:max-w-full lineheight-normal">{t("products_content")}</p>

                           <div className="lg:mx-[70px] lg:mb-[80px]">
                               <div className="w-[157px] h-[157px] lg:w-[306px] lg:h-[306px] -rotate-[11.45deg] flex justify-center items-center bg-strawberry-100 rounded-full">
                                   <p className="font-neutra text-white text-center text-[20px] lg:text-[40px] leading-none">{t("products_title")}</p>
                               </div>
                           </div>

                       </div>
                    </div>
                        <div className="absolute right-0 md:right-auto md:relative z-1 overflow-hidden md:overflow-visible">
                            <img className="w-full right-[-115px] md:right-auto max-w-[270px] md:max-w-[517px] h-auto relative" src={Mesikapp} alt=""/>
                        </div>


                </div>
            </div>
        </div>
    );
};

export default Hero;