import React from "react";
import { useTranslation } from "react-i18next";

const Events = () => {
    const { t } = useTranslation();
    const events = t("events", { returnObjects: true }) || [];

    return (
        <div className="container my-[48px] lg:my-[91px] mx-auto max-w-screen-lg relative">
            <p className="font-neutra leading-none text-strawberry-100 max-w-[590px] text-[30px] lg:text-[40px] block mx-auto text-center mb-[26px]">{t("events_title")}</p>
            {events.map((event, index) => (
                <a key={index} href={t(event.link)} rel="noreferrer" className="my-[10px] rounded-3xl font-avenirbold text-chocolate bg-lemon hover:bg-strawberry-100 hover:text-white text-[16px] lg:text-[20px] leading-none py-[10px] px-[20px] block mx-auto uppercase text-center w-fit" target="_blank"  dangerouslySetInnerHTML={{ __html: t(event.title) }}></a>
            ))}
            <p className="text-center text-strawberry-100 mt-[26px] font-avenirbold text-[20px] leading-none" dangerouslySetInnerHTML={{ __html: t("event_cta") }}></p>
        </div>
    );
};

export default Events;
