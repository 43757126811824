import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files dynamically
const resources = {
    et: {
        translation: require('./locales/et.json')
    },
    en: {
        translation: require('./locales/en.json')
    },
    ru: {
        translation: require('./locales/ru.json')
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'et', // default language
    fallbackLng: 'et',
    interpolation: {
        escapeValue: false // React already does escaping
    }
});

export default i18n;